// deps
import { Component } from "react";
import PropTypes from "prop-types";
import { Box, Link, Text, VStack } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

// components
import Splash from "../Splash";

import pkg from "../../../../package.json";

function formatMailBody({ error }) {
  return encodeURIComponent(
    `Informations techniques\n\nURL de la page\u202f: ${
      window.location.href
    }\nAgent utilisateur\u202f: ${navigator?.userAgent}\nType d’erreur\u202f: ${
      error?.name
    }\nMessage d’erreur\u202f: ${
      error?.message
    }\nContenu de l’erreur\u202f: ${error?.toString?.()}`,
  );
}

function formatAuthor({ author }) {
  return encodeURIComponent(author);
}

class ErrorBoundary extends Component {
  static getDerivedStateFromError(error) {
    return {
      error,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <VStack spacing="1rem">
          <Splash
            title="Erreur"
            description="Une erreur est survenue"
            image="error"
          />

          <Box>
            <Text fontSize="0.75rem">
              <FormattedMessage
                defaultMessage={
                  "Si cette erreur se répète, merci de prendre le temps de nous en informer en envoyant un e-mail en <link>cliquant ici</link>."
                }
                values={{
                  link: function (chunks) {
                    return (
                      <Link
                        href={`mailto:${formatAuthor({
                          author: pkg.author,
                        })}?subject=Erreur%20applicative%20Raiden&body=${formatMailBody(
                          {
                            error,
                          },
                        )}`}>
                        {chunks}
                      </Link>
                    );
                  },
                }}
              />
            </Text>
          </Box>
        </VStack>
      );
    }

    return <>{children}</>;
  }
}

ErrorBoundary.propTypes = {
  intl: PropTypes.object,
  children: PropTypes.node,
};

export default ErrorBoundary;

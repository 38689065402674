// deps
import { useRouter } from "next/router";
import cookie from "cookie";

// libraries
import getBasePath from "@splitfire-agency/raiden-library/dist/libraries/utils/getBasePath";

// constants
import browser from "@raiden/library/constants/browser";
import { COOKIES_NAME_VALUE_BYPASS_MAINTENANCE } from "@raiden/library/constants/cookies";

/**
 * Hook qui permet de savoir si le mode maintenance est activé ou non.
 */
export function useMaintenance({ cookies }) {
  const { replace, pathname, query } = useRouter();

  if ("true" === process.env.NEXT_PUBLIC_MAINTENANCE) {
    const queryBypassMaintenance = "true" === query?.bypass_maintenance;
    const cookieBypassMaintenance =
      "true" === cookies[COOKIES_NAME_VALUE_BYPASS_MAINTENANCE];

    if (
      browser &&
      queryBypassMaintenance &&
      queryBypassMaintenance !== cookieBypassMaintenance
    ) {
      const url = { path: pathname, query: { ...query } };

      delete url.query.bypass_maintenance;

      replace(url, undefined, {
        shallow: true,
      });

      document.cookie = cookie.serialize(
        COOKIES_NAME_VALUE_BYPASS_MAINTENANCE,
        "true",
        {
          path: getBasePath(process.env.NEXT_PUBLIC_ADMIN_BASE_PATH),
          secure: true,
        },
      );
    }

    return queryBypassMaintenance || cookieBypassMaintenance;
  }

  return false;
}

// hooks
import { useCallback } from "react";
import useAuthAs from "./auth/as";

export default function useCanPerform() {
  const { can } = useAuthAs();

  const canPerform = useCallback(
    /**
     * @param {{
     * authorizations?: string | string[],
     * resourcePolicies?: string[],
     * policies?: string[],
     * environmentId?: number,
     * }} props
     */
    function ({
      authorizations = null,
      resourcePolicies = [],
      policies = null,
      environmentId = null,
    }) {
      if (
        /* Si le paramètre n’est pas un tableau */
        policies &&
        !Array.isArray(policies)
      ) {
        policies = [policies];
      }

      if (authorizations && !can({ authorizations, environmentId })) {
        return false;
      }

      if (
        policies &&
        !policies.every(function (policy) {
          return resourcePolicies.includes(policy);
        })
      ) {
        return false;
      }

      return true;
    },
    [can],
  );

  return canPerform;
}

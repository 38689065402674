// deps
import { createContext, useContext, useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import cookie from "cookie";
import { useRouter } from "next/router";

// constants
import {
  GOOGLE_TRACKING_SERVICE_LIST,
  GOOGLE_TRACKING_SERVICE_VALUE_GA,
  GOOGLE_TRACKING_SERVICE_VALUE_GTM,
  GOOGLE_TRACKING_STATE_LIST,
  GOOGLE_TRACKING_STATE_VALUE_ACCEPTED,
  GOOGLE_TRACKING_STATE_VALUE_PENDING,
  GOOGLE_TRACKING_STATE_VALUE_REFUSED,
} from "../constants/googleTracking";
import { COOKIES_NAME_VALUE_ACCEPT_COOKIES } from "../constants/cookies";

// libraries
import getBasePath from "@splitfire-agency/raiden-library/dist/libraries/utils/getBasePath";
import {
  getGoogleAnalyticsScript,
  getGoogleTagManagerScript,
  trackPageView,
} from "../libraries/utils/googleTracking";

const GoogleTrackingContext = createContext();

export function GoogleTrackingProvider(props) {
  const { trackingService, trackingKey, initialState, children } = props;

  const [state, setState] = useState(initialState);

  /**
   * Est-ce qu’un service de tracking est configuré correctement.
   */
  const hasService =
    GOOGLE_TRACKING_SERVICE_LIST.some(function (tempTrackingService) {
      return trackingService === tempTrackingService.id;
    }) && trackingKey;

  /**
   * Est-ce que l’utilisateur autorise le tracking.
   */
  const canTrack = hasService && state === GOOGLE_TRACKING_STATE_VALUE_ACCEPTED;

  const { events: routerEvents } = useRouter();

  useEffect(
    function () {
      if (canTrack) {
        // eslint-disable-next-line no-inner-declarations
        function handleRouteChangeComplete() {
          trackPageView(
            window.location.pathname + window.location.search,
            trackingKey,
          );
        }

        routerEvents.on("routeChangeComplete", handleRouteChangeComplete);

        return function () {
          routerEvents.off("routeChangeComplete", handleRouteChangeComplete);
        };
      }
    },
    [canTrack, trackingKey, routerEvents],
  );

  return (
    <GoogleTrackingContext.Provider
      value={useMemo(
        function () {
          return {
            state,
            renderBanner:
              hasService && GOOGLE_TRACKING_STATE_VALUE_PENDING === state,
            acceptCookies() {
              setState(GOOGLE_TRACKING_STATE_VALUE_ACCEPTED);

              document.cookie = cookie.serialize(
                COOKIES_NAME_VALUE_ACCEPT_COOKIES,
                "1",
                {
                  path: getBasePath(process.env.NEXT_PUBLIC_ADMIN_BASE_PATH),
                  secure: true,
                },
              );
            },
            refuseCookies() {
              setState(GOOGLE_TRACKING_STATE_VALUE_REFUSED);

              document.cookie = cookie.serialize(
                COOKIES_NAME_VALUE_ACCEPT_COOKIES,
                "0",
                {
                  path: getBasePath(process.env.NEXT_PUBLIC_ADMIN_BASE_PATH),
                  secure: true,
                },
              );
            },
          };
        },
        [hasService, state],
      )}>
      {canTrack && (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${trackingKey}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          />
        </noscript>
      )}

      {canTrack &&
        GOOGLE_TRACKING_SERVICE_VALUE_GA ===
          process.env.NEXT_PUBLIC_GOOGLE_TRACKING_SERVICE &&
        getGoogleAnalyticsScript(trackingKey)}

      {canTrack &&
        GOOGLE_TRACKING_SERVICE_VALUE_GTM ===
          process.env.NEXT_PUBLIC_GOOGLE_TRACKING_SERVICE &&
        getGoogleTagManagerScript(trackingKey)}

      {children}
    </GoogleTrackingContext.Provider>
  );
}

GoogleTrackingProvider.propTypes = {
  initialState: PropTypes.oneOf(
    GOOGLE_TRACKING_STATE_LIST.map(function (googleTrackingState) {
      return googleTrackingState.id;
    }),
  ),
  trackingKey: PropTypes.string,
  trackingService: PropTypes.oneOfType([
    PropTypes.oneOf(
      GOOGLE_TRACKING_SERVICE_LIST.map(function (gogoleTrackingService) {
        return gogoleTrackingService.id;
      }),
    ),
    PropTypes.string,
  ]),
  children: PropTypes.node,
};

export function useGoogleTracking() {
  return useContext(GoogleTrackingContext);
}

// deps
import { mode } from "@chakra-ui/theme-tools";

export default {
  global: (props) => ({
    "html, body": {
      height: "100%",
      bgColor: "gray.700",
    },
    body: {
      fontFamily: "Helvetica, sans-serif",
    },
    "#__next": {
      height: "100%",
    },
    "*::placeholder": {
      color: mode("gray.400", "gray.500")(props),
    },
  }),
};

// deps
// eslint-disable-next-line import/no-unresolved
import Router from "@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router";
// eslint-disable-next-line import/no-unresolved
import Uri from "@splitfire-agency/raiden-library/dist/libraries/Uri";

export const adminBaseUri = new Uri(
  process.env.NEXT_PUBLIC_ADMIN_SCHEME,
  null,
  process.env.NEXT_PUBLIC_ADMIN_HOST,
  null,
  null,
  null,
  null,
);

export const adminBaseUriWithBasePath = new Uri(
  process.env.NEXT_PUBLIC_ADMIN_SCHEME,
  null,
  process.env.NEXT_PUBLIC_ADMIN_HOST,
  null,
  process.env.NEXT_PUBLIC_ADMIN_BASE_PATH,
  null,
  null,
);

/**
 * Build a router.
 * @param {import ("@splitfire-agency/raiden-library/dist/libraries/Uri").default} baseUri
 * @return {import ("@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router").default})}
 */
function buildRouter(baseUri) {
  const adminRouter = new Router();

  adminRouter.group(baseUri, (adminRouter) => {
    // assets (previewer)
    adminRouter
      .get("/public/<filePath>")
      .setId("assets")
      .setConstraint("filePath", /.*/);

    // internal assets (nextjs)
    adminRouter
      .get(`/<filePath>`)
      .setId("internal-assets")
      .setConstraint("filePath", /.*/);

    adminRouter.get("").setId("home");

    // Login
    adminRouter.get("/login").setId("login");

    // Password recovery
    adminRouter.get("/password-recovery").setId("password-recovery");

    // Dashboard
    adminRouter.get("/dashboard").setId("dashboard");

    // Health
    adminRouter.group("/health/status", (adminRouter) => {
      adminRouter.get("").setId("@healthStatus.view");
    });

    // Internal
    adminRouter.group("/internal", (adminRouter) => {
      adminRouter.group("/horizon", (adminRouter) => {
        adminRouter.get("").setId("@internalHorizon.view");
      });
      adminRouter.group("/telescope", (adminRouter) => {
        adminRouter.get("").setId("@internalTelescope.view");
      });
    });

    // Environments
    adminRouter.group("/environments", (adminRouter) => {
      adminRouter.get("").setId("@environments.viewAny");

      adminRouter.group("/<environmentId>", (adminRouter) => {
        adminRouter.setConstraint("environmentId", /\d+/);

        adminRouter.get("/show").setId("@environments.view");

        adminRouter.group("/edit", (adminRouter) => {
          adminRouter.get("").setId("@environments.edit");
          adminRouter
            .get("/records_accounts")
            .setId("@environments.editRecordsAccounts");
        });
      });
    });

    adminRouter.group("/users", (adminRouter) => {
      // Users/Admins
      adminRouter.group("/admins", (adminRouter) => {
        adminRouter.get("").setId("@usersAdmins.viewAny");

        adminRouter.get("/create").setId("@usersAdmins.create");

        adminRouter.get("/me").setId("@usersAdmins.me");

        adminRouter.group("/<usersAdminId>", (adminRouter) => {
          adminRouter.setConstraint("usersAdminId", /\d+/);

          adminRouter.get("/show").setId("@usersAdmins.view");

          adminRouter.get("/edit").setId("@usersAdmins.update");
        });
      });

      // Users/Groups
      adminRouter.group("/groups", (adminRouter) => {
        adminRouter.get("").setId("@usersGroups.viewAny");

        adminRouter.get("/create").setId("@usersGroups.create");

        adminRouter.group("/<usersGroupId>", (adminRouter) => {
          adminRouter.setConstraint("usersGroupId", /\d+/);

          adminRouter.get("/show").setId("@usersGroups.view");

          adminRouter.get("/edit").setId("@usersGroups.update");
        });
      });
    });

    adminRouter.group("/posts", (adminRouter) => {
      adminRouter.group("/<postType>", (adminRouter) => {
        adminRouter.setConstraint("postType", /[a-z]+/);

        adminRouter.get("/create").setId("@posts.create");

        adminRouter.get("").setId("@posts.viewAny");

        adminRouter.group("/categories", (adminRouter) => {
          adminRouter.get("").setId("@postsCategories.viewAny");

          adminRouter.get("/create").setId("@postsCategories.create");

          adminRouter.get("/tree-edit").setId("@postsCategories.treeUpdate");

          adminRouter.group("/<postsCategoryId>", (adminRouter) => {
            adminRouter.get("/show").setId("@postsCategories.view");

            adminRouter.get("/edit").setId("@postsCategories.update");
          });
        });

        adminRouter.group("/<postId>", (adminRouter) => {
          adminRouter.setConstraint("postId", /\d+/);

          adminRouter.get("/show").setId("@posts.view");

          adminRouter.get("/edit-revision").setId("@posts.updateRevision");
        });
      });
    });

    // Countries
    adminRouter.group("/countries", (adminRouter) => {
      adminRouter.get("").setId("@countries.viewAny");
      adminRouter.get("/create").setId("@countries.create");
      adminRouter.group("/<countryId>", (adminRouter) => {
        adminRouter.setConstraint("countryId", /\d+/);
        adminRouter.get("/edit").setId("@countries.update");
      });
    });

    // Menus
    adminRouter.group("/menus", (adminRouter) => {
      adminRouter.get("").setId("@menus.viewAny");
      adminRouter.get("/create").setId("@menus.create");

      adminRouter.group("/<menuId>", (adminRouter) => {
        adminRouter.get("/view").setId("@menus.view");
        adminRouter.get("/update").setId("@menus.update");
      });
    });

    // Invoices
    adminRouter.group("/invoices", (adminRouter) => {
      adminRouter.get("").setId("@invoices.viewAny");
    });

    // Account records
    adminRouter.group("/account-records", (adminRouter) => {
      adminRouter.get("").setId("@accountRecords.viewAny");
    });

    // Clients
    adminRouter.group("/clients", (adminRouter) => {
      adminRouter.get("").setId("@customers.viewAny");
      adminRouter.get("/create").setId("@customers.create");

      adminRouter.group("/<customerId>", (adminRouter) => {
        adminRouter.setConstraint("customerId", /\d+/);
        adminRouter.get("/show").setId("@customers.view");
        adminRouter.get("/edit").setId("@customers.update");
      });
    });

    // Carriers
    adminRouter.group("/carriers", (adminRouter) => {
      adminRouter.get("").setId("@carriers.viewAny");
      adminRouter.get("/create").setId("@carriers.create");

      adminRouter.group("/<carrierId>", (adminRouter) => {
        adminRouter.setConstraint("carrierId", /\d+/);
        adminRouter.get("/show").setId("@carriers.view");
        adminRouter.get("/edit").setId("@carriers.update");
      });
    });

    // Api
    adminRouter.group("/api", (adminRouter) => {
      // Pampa
      adminRouter
        .get("/resize<slug>")
        .setId("pampa")
        .setConstraint("slug", /.*/);
    });
  });

  return adminRouter;
}

export const adminRouter = buildRouter(adminBaseUri);
export const adminRouterWithBasePath = buildRouter(adminBaseUriWithBasePath);

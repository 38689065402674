// deps
import { mode } from "@chakra-ui/theme-tools";

export default {
  baseStyle(props) {
    const { colorScheme: c } = props;

    if (c) {
      return {
        color: mode(`${c}.600`, `${c}.300`)(props),
      };
    }
  },
};

// deps
import { PHASE_PRODUCTION_BUILD } from "next/constants";

// hooks
import { serverApiFetcher } from "../../../hooks/useApiFetcher";

// libraries
import generateApiUri from "../../../libraries/utils/generateApiUri";

export default async function nextGetEnvironments({
  cookies,
  baseUri,
  locale,
  logged,
}) {
  if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD || !logged) {
    return {
      data: {},
    };
  }

  const environmentsData = await serverApiFetcher(
    generateApiUri({
      id: "@environments.search",
    }),
    undefined,
    {
      cookies,
      origin: baseUri.toString(),
      locale,
    },
  ).catch((error) => {
    console.error("Unable to fetch environments (nextGetEnvironments)", error);
  });

  return {
    data: environmentsData,
    environment: environmentsData?.data,
  };
}

// deps
import {
  Box,
  Flex,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import Router, { useRouter } from "next/router";
import PropTypes from "prop-types";
import { useEffect } from "react";

// containers
import SignedInFooter from "./Footer";
import SignedInHeader from "./Header";

// hooks
import useAuthAs from "@raiden/library/hooks/auth/as";

// libraries
import generateAdminPath from "@raiden/library/libraries/utils/generateAdminPath";

// contexts
import { DefaultEnvironmentsProvider } from "../../contexts/DefaultEnvironments";

// constants
import { USERS_USER_TYPE_VALUE_ADMIN } from "@raiden/library/constants/users";
import { MENU_ITEMS } from "../../constants/menu";
import Menu from "./Menu";

export default function SignedInLayout(props) {
  const { children } = props;

  const isMobileLayout = useBreakpointValue({
    base: true,
    lg: false,
  });

  const {
    isOpen: isOpenMenu,
    onClose: onCloseMenu,
    onToggle: onToggleMenu,
  } = useDisclosure();

  const router = useRouter();

  useEffect(() => {
    const handler = () => {
      onCloseMenu();
    };
    router.events.on("routeChangeStart", handler);
    return () => {
      router.events.off("routeChangeStart", handler);
    };
  }, [onCloseMenu, router.events]);

  const bgColor = useColorModeValue("white", "gray.800");

  const { logged, loading, user } = useAuthAs();

  useEffect(
    function () {
      if (
        !loading &&
        (!logged || USERS_USER_TYPE_VALUE_ADMIN !== user?.user_type)
      ) {
        Router.push({
          pathname: generateAdminPath({ id: "login" }),
          query: {
            next: Router.asPath,
          },
        });
      }
    },
    [logged, loading, user?.user_type],
  );

  return (
    <DefaultEnvironmentsProvider>
      <Flex h="full" flexDir="column" pos="relative">
        <SignedInHeader onClickMenu={onToggleMenu} />

        <Flex flexGrow={1} position="relative" mt="4rem">
          <Flex flexGrow={1} flexDir="column" bgColor={bgColor} minW="0">
            <Box
              flexGrow={1}
              d="flex"
              flexDir="column"
              ml={isMobileLayout ? "0rem" : "15rem"}
              transition="margin .25s ease-in-out">
              {children}
            </Box>
            <SignedInFooter />
          </Flex>
          <Box flexShrink={0}>
            <Menu
              menuItems={MENU_ITEMS}
              isOpened={isOpenMenu}
              onClose={onCloseMenu}
              isMobileLayout={isMobileLayout}
            />
          </Box>
        </Flex>
      </Flex>
    </DefaultEnvironmentsProvider>
  );
}

SignedInLayout.propTypes = {
  children: PropTypes.node,
};

// constants
import browser from "@splitfire-agency/raiden-library/dist/constants/browser";

// libraries
import UriImmutable from "@splitfire-agency/raiden-library/dist/libraries/UriImmutable";

/**
 * Récupère l’Uri courant.
 * @param {object} param0
 * @param {import("http").IncomingMessage} param0.req
 * @return {UriImmutable}
 */
export default function nextGetUri({ req }) {
  if (browser) {
    return new UriImmutable(
      window.location.protocol.slice(0, -1),
      undefined,
      window.location.hostname,
      window.location.port,
      window.location.pathname,
      window.location.search.slice(1),
    );
  }

  const { URL } = require("url");

  const url = new URL(
    req.url,
    `${req.headers["x-forwarded-proto"] ?? req.protocol}://${
      req.headers["x-forwarded-host"] ?? req.headers.host
    }`,
  );

  return new UriImmutable(
    url.protocol.slice(0, -1),
    undefined,
    url.hostname,
    url.port ? url.port : null,
    url.pathname,
    url.search.slice(1),
  );
}

// libraries
import noop from "@splitfire-agency/raiden-library/dist/libraries/utils/noop";

// constants
import browser from "../../../constants/browser";

/**
 * Force l’appel au serveur pour le rendu de cette page.
 * @param {object} param0
 * @param {string} param0.path
 */
export default async function nextForceSsr({ path }) {
  if (browser) {
    window.location.href = path;

    /*
     * /!\ Promesse qui ne se résoudra jamais.
     * Cela est volontaire car on ne souhaite pas que la suite du `getInitialProps` soit appelé et
     *   comme la redirection est déjà enclenchée, on attend quelle soit pleinement effectuée.
     */
    await new Promise(noop);
  }
}

// deps
import { mode } from "@chakra-ui/theme-tools";

export default {
  defaultProps: {
    colorScheme: "brandPrimary",
  },
  variants: {
    pagination(props) {
      const { colorScheme: c } = props;

      return {
        bgColor: "transparent",
        borderColor: mode(`${c}.400`, `${c}.500`)(props),
        borderStyle: "solid",
        borderWidth: 1,
        color: mode(`${c}.400`, `${c}.500`)(props),
        _hover: {
          borderColor: "brandSecondary.500",
          color: "brandSecondary.500",
          textDecor: "none",
        },
        _active: {
          borderColor: "brandSecondary.500",
          color: "brandSecondary.500",
        },
      };
    },
    paginationDots(props) {
      const { colorScheme: c } = props;

      return {
        bgColor: "transparent",
        borderColor: "transparent",
        borderStyle: "solid",
        borderWidth: 1,
        color: mode(`${c}.400`, `${c}.500`)(props),
        _hover: {
          borderColor: "brandSecondary.500",
          color: "brandSecondary.500",
        },
        _active: {
          borderColor: "brandSecondary.500",
          color: "brandSecondary.500",
        },
      };
    },
    solid(props) {
      const { colorScheme: c } = props;

      if ("brandPrimary" === c) {
        const bg = "brandPrimary.500";

        return {
          bg,
          color: "white",
          _hover: {
            bg: "brandPrimary.500",
            _disabled: {
              bg,
            },
          },
          _active: "brandPrimary.500",
        };
      }
    },
    outline(props) {
      const { colorScheme: c } = props;

      if (c === "gray") {
        return {
          color: mode(`gray.700`, `whiteAlpha.900`)(props),
          borderColor: mode("gray.200", "gray.600")(props),
          _hover: {
            bg: mode(`gray.100`, `whiteAlpha.200`)(props),
          },
          _active: { bg: mode(`gray.200`, `whiteAlpha.300`)(props) },
        };
      }
    },
  },
};

// deps
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";

export default function MaintenanceLayout(props) {
  const intl = useIntl();

  return (
    <Alert
      status="error"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="full">
      <AlertIcon boxSize="40px" mr={0} />

      <AlertTitle mt={4} mb={1} fontSize="lg">
        {intl.formatMessage({
          id: "raiden.admin.containers.MaintenanceLayout.texts.title",
          defaultMessage: "Maintenance en cours",
        })}
      </AlertTitle>

      <AlertDescription maxWidth="sm">
        {intl.formatMessage({
          id: "raiden.admin.containers.MaintenanceLayout.texts.subtitle",
          defaultMessage:
            "Votre site est en cours de maitenance, nous vous invitons à revenir dans quelques minutes.",
        })}
      </AlertDescription>
    </Alert>
  );
}

// deps
import { useCallback, useEffect, useState } from "react";
import useSWR from "swr";

// libraries
import generateApiUri from "../../libraries/utils/generateApiUri";

// hooks
import { browserApiFetcher } from "../useApiFetcher";
import useLocale from "../useLocale";

// helpers
import usersCan from "../../helpers/users/can";
import usersIsRoot from "../../helpers/users/isRoot";

// contexts
import { useInitialVariables } from "@splitfire-agency/raiden-library/dist/contexts/InitialVariables";

export default function useAuthAs() {
  const { locale } = useLocale();

  const { userData } = useInitialVariables();

  const { data, mutate, error } = useSWR(
    generateApiUri({
      id: "@auth.me",
      query: {
        fields: ["user.avatar"],
      },
    }),
    browserApiFetcher,
    {
      dedupingInterval: 10000,
      locale,
      logout() {
        mutate({});
      },
      fallbackData: userData,
    },
  );

  const loading = !data && !error;

  const user = data?.data;

  const logged = Boolean(user?.id);

  const root = usersIsRoot({ user });

  const [remanentUser, setRemanentUser] = useState(user);

  useEffect(
    function () {
      if (!remanentUser && user) {
        setRemanentUser(user);
      } else if (remanentUser?.id && user?.id && remanentUser !== user) {
        setRemanentUser(user);
      }
    },
    [user, remanentUser, setRemanentUser],
  );

  /**
   * Déconnecte l’utilisateur.
   * À n’appeler qu’après avoir invoqué l’endpoint de
   *   déconnexion ou après avoir été déconnecté naturellement (par expiration).
   */
  const logout = useCallback(
    function () {
      mutate({});
    },
    [mutate],
  );

  /**
   * Connecte l’utilisateur.
   */
  const login = useCallback(
    function (user) {
      mutate(user);
    },
    [mutate],
  );

  const can = useCallback(
    function ({ authorizations, environmentId }) {
      return root || usersCan({ authorizations, environmentId, user });
    },
    [root, user],
  );

  return {
    loading,
    logged,
    user,
    remanentUser,
    login,
    logout,
    can,
    root,
  };
}

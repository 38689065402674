// hooks
import useResource from "@splitfire-agency/raiden-library/dist/hooks/useResource";
import useApiFetcher from "@raiden/library/hooks/useApiFetcher";

// libraries
import generateApiUri from "@raiden/library/libraries/utils/generateApiUri";

export default function useEnvironments() {
  const apiFetcher = useApiFetcher();

  return useResource(
    generateApiUri({
      id: "@environments.search",
    }),
    "environments",
    apiFetcher,
  );
}

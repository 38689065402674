/* Autorisations */

export const AUTHORIZATIONS_AUTHORIZATION_VALUE_COUNTRIES_VIEW_ANY =
  "countries.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_COUNTRIES_VIEW =
  "countries.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_COUNTRIES_CREATE =
  "countries.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_COUNTRIES_UPDATE =
  "countries.update";

// Environments
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_VIEW =
  "environments.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_UPDATE =
  "environments.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_UPDATE_RECORDS_ACCOUNTS =
  "environments.update_records_accounts";

// Users/Groups
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_VIEW_ANY =
  "users.groups.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_VIEW =
  "users.groups.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_CREATE =
  "users.groups.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_UPDATE =
  "users.groups.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_DELETE =
  "users.groups.delete";

// Users/Admins
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_VIEW_ANY =
  "users.admins.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_VIEW =
  "users.admins.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_CREATE =
  "users.admins.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_UPDATE =
  "users.admins.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_DELETE =
  "users.admins.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_PASSWORD_RESETS =
  "users.admins.password_resets";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_ACCESS_TOKENS =
  "users.admins.access_tokens";

// Posts
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_VIEW_ANY =
  "posts.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_VIEW = "posts.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CREATE = "posts.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_UPDATE = "posts.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_SOFT_DELETE =
  "posts.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_DELETE = "posts.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_RESTORE = "posts.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_PUBLISH = "posts.publish";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_DISABLE = "posts.disable";

// Posts/Revisions
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_VIEW_ANY =
  "posts.revisions.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_VIEW =
  "posts.revisions.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_UPDATE =
  "posts.revisions.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_DELETE =
  "posts.revisions.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_SOFT_DELETE =
  "posts.revisions.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_RESTORE =
  "posts.revisions.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_REVIEW =
  "posts.revisions.review";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_REQUEST_REVIEW =
  "posts.revisions.request_review";

// Posts/Categories
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_VIEW_ANY =
  "posts.categories.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_VIEW =
  "posts.categories.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_CREATE =
  "posts.categories.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_UPDATE =
  "posts.categories.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_SOFT_DELETE =
  "posts.categories.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_DELETE =
  "posts.categories.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_RESTORE =
  "posts.categories.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_UPDATE_TREE =
  "posts.categories.update_tree";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_VIEW_TREE =
  "posts.categories.view_tree";

// Files
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_VIEW_ANY =
  "files.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_VIEW = "files.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_CREATE = "files.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_UPDATE = "files.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_DELETE = "files.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_SOFT_DELETE =
  "files.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_RESTORE = "files.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_DOWNLOAD =
  "files.download";

// Menus
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_VIEW_ANY =
  "menus.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_UPDATE = "menus.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_UPDATE_ITEMS =
  "menus.update_items";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_VIEW = "menus.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_DELETE = "menus.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_SOFT_DELETE =
  "menus.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_RESTORE = "menus.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_CREATE = "menus.create";

// Invoices
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_INVOICES_VIEW_ANY =
  "invoices.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_INVOICES_DOWNLOAD =
  "invoices.download";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_INVOICES_EXPORT =
  "invoices.export";

// accounts_records
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ACCOUNTS_RECORDS_VALUE_VIEW_ANY =
  "accounts_records_exports.view_any";

// Customers
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_VIEW_ANY =
  "users.customers.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_VIEW =
  "users.customers.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CREATE =
  "users.customers.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_UPDATE =
  "users.customers.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_DELETE =
  "users.customers.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_SOFT_DELETE =
  "users.customers.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_RESTORE =
  "users.customers.soft_restore";

// Adresses
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ADDRESSES_VIEW_ANY =
  "users.addresses.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ADDRESSES_VIEW =
  "users.addresses.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ADDRESSES_CREATE =
  "users.addresses.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ADDRESSES_UPDATE =
  "users.addresses.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ADDRESSES_DELETE =
  "users.addresses.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ADDRESSES_SOFT_DELETE =
  "users.addresses.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ADDRESSES_RESTORE =
  "users.addresses.restore";

// Carriers
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_VIEW_ANY =
  "carriers.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_VIEW = "carriers.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_CREATE =
  "carriers.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_UPDATE =
  "carriers.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_SOFT_DELETE =
  "carriers.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_DELETE =
  "carriers.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_RESTORE =
  "carriers.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_ASSOCIATE =
  "carriers.associate";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_DISSOCIATE =
  "carriers.dissociate";

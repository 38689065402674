// deps
import { useCallback } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import {
  Box,
  Button,
  DarkMode,
  Flex,
  Grid,
  GridItem,
  HStack,
  Menu,
  MenuButton,
  Portal,
  Skeleton,
  Switch,
  Text,
  Divider,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";

// components
import { IoChevronDown, IoMenu } from "@raiden/library/components/Icon";
import Logo from "../../../components/Logo";
import EnvironmentSelect from "../../../components/EnvironmentSelect";
import UsersAvatar from "../../../components/Users/Avatar";

// containers
import UserMenu from "../../UserMenu";

// hooks
import useAuthAs from "@raiden/library/hooks/auth/as";

// contexts
import { useDefaultEnvironments } from "../../../contexts/DefaultEnvironments";
import { usePreferences } from "../../../contexts/Preferences";

export default function SignedInLayoutHeader(props) {
  const { onClickMenu } = props;

  const { remanentUser, loading } = useAuthAs();

  const { defaultEnvironments, setDefaultEnvironments } =
    useDefaultEnvironments();

  const intl = useIntl();

  const { colorMode, setColorMode } = useColorMode();

  const responsive = useBreakpointValue({
    base: true,
    lg: false,
  });

  const { shouldRenderEnvironments } = usePreferences();

  /**
   * Gère le changement de thème.
   */
  const handleChangeTheme = useCallback(
    function (event) {
      setColorMode(event.target.checked ? "dark" : "light");
    },
    [setColorMode],
  );

  /**
   * Gère le changement d’environnement par défaut.
   */
  const handleChangeDefaultEnvironment = useCallback(
    function (event) {
      setDefaultEnvironments(event.target.value);
    },
    [setDefaultEnvironments],
  );

  return (
    <DarkMode>
      <Flex
        pos="fixed"
        zIndex="1"
        left="0"
        right="0"
        top="0"
        h="4rem"
        borderBottomColor="gray.600"
        borderBottomWidth="0.0625rem"
        borderBottomStyle="solid"
        alignItems="center"
        px="0.625rem"
        py="0.75rem"
        bgColor="gray.700"
        color="white">
        {responsive ? (
          <Grid templateColumns="repeat(3, 1fr)" gap={"0.3125rem"} w="full">
            <GridItem>
              <HStack
                spacing="0.625rem"
                as="button"
                h="full"
                onClick={onClickMenu}>
                <IoMenu w="1.5rem" h="1.5rem" />

                <Text>
                  {intl.formatMessage({
                    id: "raiden.admin.containers.SignedInLayout.texts.menu",
                    defaultMessage: "Menu",
                  })}
                </Text>
              </HStack>
            </GridItem>

            <GridItem>
              <Flex h="3rem" justifyContent="center">
                <Logo dimensions={[130, 48]} />
              </Flex>
            </GridItem>
          </Grid>
        ) : (
          <>
            <Box flexGrow={1}>
              <Box h="3rem">
                <Logo dimensions={[130, 48]} />
              </Box>
            </Box>

            <HStack spacing="1rem">
              <HStack spacing="0.3125rem">
                <Switch
                  size="sm"
                  id="header-theme-switcher"
                  isChecked={"dark" === colorMode}
                  onChange={handleChangeTheme}
                />

                <Text as="label" htmlFor="header-theme-switcher">
                  {intl.formatMessage(
                    {
                      id: "raiden.admin.containers.SignedInLayout.Header.texts.colorMode",
                      defaultMessage:
                        "{colorMode, select, dark {Thème sombre} other {Thème clair}}",
                    },
                    {
                      colorMode,
                    },
                  )}
                </Text>
              </HStack>

              {shouldRenderEnvironments && (
                <>
                  <Divider
                    borderColor="gray.600"
                    orientation="vertical"
                    h="2.5rem"
                  />

                  <EnvironmentSelect
                    width="15.625rem"
                    isMultiple={true}
                    value={defaultEnvironments}
                    onChange={handleChangeDefaultEnvironment}
                  />
                </>
              )}

              <Divider
                borderColor="gray.600"
                orientation="vertical"
                h="2.5rem"
              />

              {loading ? (
                <Skeleton w="15.625rem" h="2.5rem" />
              ) : (
                <Menu placement="bottom">
                  <MenuButton
                    as={Button}
                    rightIcon={<IoChevronDown />}
                    variant="ghost"
                    fontWeight="normal">
                    <UsersAvatar size="md" user={remanentUser} />
                  </MenuButton>

                  <Portal>
                    <UserMenu />
                  </Portal>
                </Menu>
              )}
            </HStack>
          </>
        )}
      </Flex>
    </DarkMode>
  );
}

SignedInLayoutHeader.propTypes = {
  onClickMenu: PropTypes.func,
};

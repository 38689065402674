// deps
import { useCallback } from "react";

// hooks
import useLocale from "@raiden/library/hooks/useLocale";
import { Select } from "@chakra-ui/react";

// constants
import { LOCALES_LANG_NAMES } from "@raiden/library/constants/locales";

export default function LocaleSelect(props) {
  const { ...otherProps } = props;

  const { locale, locales, setLocale } = useLocale();

  /**
   * Change la langue utilisée.
   */
  const handleChangeLocale = useCallback(
    function (event) {
      const locale = event.target.value;

      setLocale(locale, { saveInCookie: true });
    },
    [setLocale],
  );

  return (
    <>
      {locales.length > 1 && (
        <Select
          {...otherProps}
          w="auto"
          placeholder=""
          value={locale}
          onChange={handleChangeLocale}>
          {locales.map((locale) => {
            if (locale === "default") {
              return;
            }
            return (
              <option key={locale} value={locale}>
                {LOCALES_LANG_NAMES[locale].name}
              </option>
            );
          })}
        </Select>
      )}
    </>
  );
}

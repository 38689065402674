// deps
import {
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import cookie from "cookie";
import dayjs from "dayjs";

// constants
import {
  COOKIES_NAME_VALUE_PAGINATION_PER_PAGE,
  COOKIES_NAME_VALUE_PREFER_MENU_SHRINK,
} from "@raiden/library/constants/cookies";

// libraries
import getBasePath from "@splitfire-agency/raiden-library/dist/libraries/utils/getBasePath";

export const PreferencesContext = createContext(null);

const { Provider } = PreferencesContext;

function _PreferencesProvider(props) {
  const {
    initialPaginationPerPage,
    initialPreferMenuShrink,
    initialEnvironments,
    children,
  } = props;

  const paginationPerPage = useRef(Number(initialPaginationPerPage));
  const preferMenuShrink = useRef(initialPreferMenuShrink);
  const shouldRenderEnvironments = useRef(initialEnvironments.length > 1);
  const [bestEnvironment, setBestEnvironment] = useState(
    initialEnvironments.length === 1 ? initialEnvironments[0] : null,
  );

  const previousInitialEnvironments = useRef(initialEnvironments);

  useEffect(
    function () {
      if (
        !previousInitialEnvironments.current.some(function (
          previousInitialEnvironment,
          previousInitialEnvironmentIndex,
        ) {
          return (
            !(previousInitialEnvironmentIndex in initialEnvironments) ||
            previousInitialEnvironment.id !==
              initialEnvironments[previousInitialEnvironmentIndex].id
          );
        })
      ) {
        previousInitialEnvironments.current = initialEnvironments;
        setBestEnvironment(
          initialEnvironments.length === 1 ? initialEnvironments[0] : null,
        );
      }
    },
    [initialEnvironments],
  );

  const value = useMemo(
    function () {
      return {
        preferMenuShrink: preferMenuShrink.current,
        setPreferMenuShrink(newPreferMenuShrink) {
          document.cookie = cookie.serialize(
            COOKIES_NAME_VALUE_PREFER_MENU_SHRINK,
            newPreferMenuShrink ? "1" : "0",
            {
              expires: dayjs().add(1, "years").toDate(),
              path: getBasePath(process.env.NEXT_PUBLIC_ADMIN_BASE_PATH),
              secure: true,
            },
          );

          preferMenuShrink.current = newPreferMenuShrink;
        },
        paginationPerPage: paginationPerPage.current,
        setPaginationPerPage(newPaginationPerPage) {
          document.cookie = cookie.serialize(
            COOKIES_NAME_VALUE_PAGINATION_PER_PAGE,
            newPaginationPerPage,
            {
              expires: dayjs().add(1, "years").toDate(),
              path: getBasePath(process.env.NEXT_PUBLIC_ADMIN_BASE_PATH),
              secure: true,
            },
          );

          paginationPerPage.current = newPaginationPerPage;
        },
        shouldRenderEnvironments: shouldRenderEnvironments.current,
        bestEnvironment,
      };
    },
    [bestEnvironment],
  );

  return <Provider value={value}>{children}</Provider>;
}

_PreferencesProvider.displayName = "PreferencesProvider";

_PreferencesProvider.propTypes = {
  initialPreferMenuShrink: PropTypes.bool,
  initialEnvironments: PropTypes.any,
  initialPaginationPerPage: PropTypes.any,
  children: PropTypes.node,
};

export const PreferencesProvider = memo(_PreferencesProvider);

export const usePreferences = function () {
  return useContext(PreferencesContext);
};

// deps
import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";

/**
 * @param {import("@chakra-ui/react").BoxProps & {
 * children:import("react").ReactNode,
 * }} props
 */
export default function PagePanelViewport(props) {
  const { children, ...otherProps } = props;

  return (
    <Box
      {...otherProps}
      flexGrow={1}
      d="flex"
      flexDir="column"
      justifyContent="center">
      {children}
    </Box>
  );
}

PagePanelViewport.propTypes = {
  children: PropTypes.node,
};

// deps
import PropTypes from "prop-types";
import { Select } from "@chakra-ui/react";
import { forwardRef } from "react";
import { useIntl } from "react-intl";

// components
import SelectMultiple from "@splitfire-agency/raiden-library/dist/components/SelectMultiple";

// hooks
import useEnvironments from "../../hooks/api/environments/search";

/**
 * @typedef {object} Props
 * @property {boolean} [isMultiple]
 * @property {boolean} [isDisabled]
 * @property {string} placeholder
 * @property {boolean} [includeWildcard]
 */

const EnvironmentSelect = forwardRef(
  /**
   * @param {Props} props
   * @return {import("react").FunctionComponentElement<Props>}
   */
  function EnvironmentSelect(props, ref) {
    const {
      isMultiple = false,
      isDisabled = false,
      placeholder,
      includeWildcard = false,
      ...otherProps
    } = props;

    const { environments, loading } = useEnvironments();

    const intl = useIntl();

    return (
      <>
        {isMultiple ? (
          <SelectMultiple
            {...otherProps}
            isDisabled={isDisabled}
            placeholder={
              placeholder ??
              intl.formatMessage({
                id: "raiden.admin.components.EnvironmentSelect.texts.placeholder",
                defaultMessage: "Environnement",
              })
            }
            label={function ({ count }) {
              return placeholder
                ? intl.formatMessage(
                    {
                      id: "raiden.admin.components.EnvironmentSelect.texts.label.withPlaceholder",
                      defaultMessage:
                        "{count, plural, =0 {{placeholder}} one {un environnement} other {# environnements}}",
                    },
                    { count, placeholder },
                  )
                : intl.formatMessage(
                    {
                      id: "raiden.admin.components.EnvironmentSelect.texts.label.withoutPlaceholder",
                      defaultMessage:
                        "{count, plural, =0 {aucun environnement} one {un environnement} other {# environnements}}",
                    },
                    { count },
                  );
            }}>
            {includeWildcard && (
              <option value="*">
                {intl.formatMessage({
                  id: "raiden.admin.components.EnvironmentSelect.texts.wildcard",
                  defaultMessage: "Tout environnement confondu",
                })}
              </option>
            )}
            {(environments?.data ?? []).map(function (environment) {
              return (
                <option key={environment.id} value={String(environment.id)}>
                  {environment.name}
                </option>
              );
            })}
          </SelectMultiple>
        ) : (
          <Select
            {...otherProps}
            isDisabled={isDisabled}
            placeholder={
              placeholder ??
              intl.formatMessage({
                id: "raiden.admin.components.EnvironmentSelect.texts.placeholder",
                defaultMessage: "Environnement",
              })
            }
            ref={ref}>
            {loading ? (
              <optgroup
                label={intl.formatMessage({
                  id: "raiden.admin.components.EnvironmentSelect.texts.loading",
                  defaultMessage: "Chargement en cours…",
                })}
              />
            ) : (
              <>
                {includeWildcard && (
                  <option key="*" value="*">
                    {intl.formatMessage({
                      id: "raiden.admin.components.EnvironmentSelect.texts.wildcard",
                      defaultMessage: "Tout environnement confondu",
                    })}
                  </option>
                )}
                {(environments?.data ?? []).map(function (environment) {
                  return (
                    <option key={environment.id} value={environment.id}>
                      {environment.name}
                    </option>
                  );
                })}
              </>
            )}
          </Select>
        )}
      </>
    );
  },
);

EnvironmentSelect.displayName = "EnvironmentSelect";

EnvironmentSelect.propTypes = {
  includeWildcard: PropTypes.bool,
  placeholder: PropTypes.string,
  isMultiple: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default EnvironmentSelect;

// deps
import { MenuItem, MenuList, useToast } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useIntl } from "react-intl";
import NextLink from "next/link";

// hooks
import useApiFetcher from "@raiden/library/hooks/useApiFetcher";
import useAuthAs from "@raiden/library/hooks/auth/as";

// libraries
import generateApiUri from "@raiden/library/libraries/utils/generateApiUri";
import generateAdminPath from "@raiden/library/libraries/utils/generateAdminPath";

export default function UserMenu(props) {
  const { logout } = useAuthAs();

  const intl = useIntl();

  const router = useRouter();

  const toast = useToast();

  const apiFetcher = useApiFetcher();

  /**
   * Gère le clic sur le bouton de déconnexion
   */
  function handleClickLogout() {
    apiFetcher(
      generateApiUri({
        id: "@auth.logout",
      }),
      {
        method: "POST",
      },
    ).then(function () {
      toast({
        id: "logout",
        status: "success",
        title: intl.formatMessage({
          id: "raiden.admin.containers.UserMenu.texts.toast.logout.title",
          defaultMessage: "Déconnexion réussie",
        }),
        position: "bottom-right",
      });

      router.push({ pathname: generateAdminPath({ id: "login" }) });

      logout();
    });
  }

  return (
    <MenuList>
      <NextLink
        href={generateAdminPath({ id: "@usersAdmins.me" })}
        passHref={true}>
        <MenuItem as="a" color="white">
          {intl.formatMessage({
            id: "raiden.admin.containers.UserMenu.texts.menu.edit",
            defaultMessage: "Mon compte",
          })}
        </MenuItem>
      </NextLink>

      <MenuItem onClick={handleClickLogout} color="red.500">
        {intl.formatMessage({
          id: "raiden.admin.containers.UserMenu.texts.menu.logout",
          defaultMessage: "Me déconnecter",
        })}
      </MenuItem>
    </MenuList>
  );
}

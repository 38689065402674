// deps
import Script from "next/script";

export function getGoogleTagManagerScript(key) {
  if (null == key) {
    return null;
  }
  return (
    <Script
      id="google-tag-manager"
      strategy="afterInteractive">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${key}');`}</Script>
  );
}

export function getGoogleAnalyticsScript(key) {
  if (null == key) {
    return null;
  }

  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${key}`}
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${key}', {page_path: window.location.pathname,});`}
      </Script>
    </>
  );
}

export function getGoogleTagManagerNoScript(key) {
  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${key}`}
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      />
    </noscript>
  );
}

export function trackPageView(url, key) {
  try {
    window.gtag("config", key, {
      page_path: url,
    });
  } catch (error) {
    console.warn("can't track page", error);
  }
}

import {
  Box,
  DarkMode,
  Divider,
  Flex,
  Menu as ChakraMenu,
  MenuButton as ChakraMenuButton,
  Portal,
  Spinner,
  Stack,
  Switch,
  useColorMode,
} from "@chakra-ui/react";
import useAuthAs from "@raiden/library/hooks/auth/as";
import useCanPerform from "@raiden/library/hooks/useCanPerform";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import UsersAvatar from "../../../components/Users/Avatar";
import { useScrollBlocker } from "../../../hooks/useScrollBlocker";
import UserMenu from "../../UserMenu";
import MenuItem, { isActivePath } from "./MenuItem";
import { useRouter } from "next/router";

/**
 * Trim menu items & add unique id
 * @param {import("./MenuItem").Item[]} menuItems
 * @param {{
 * canPerform: (params: { authorizations: string | string[]}) => boolean,
 * isRoot: boolean
 * }} param1
 * @param {number} [depth]
 * @returns {import("./MenuItem").Item[]}
 */
export function prepare(menuItems = [], { canPerform, isRoot }, depth = 0) {
  const trimmed = [];
  menuItems?.forEach((menuItem) => {
    const trimmedChildren = prepare(menuItem.children, { canPerform, isRoot });
    if (
      (menuItem?.validator?.({ canPerform, isRoot }) ?? true) &&
      (menuItem?.href?.length > 0 ||
        typeof menuItem?.onClick === "function" ||
        trimmedChildren.length > 0)
    ) {
      trimmed.push({
        ...menuItem,
        children: prepare(menuItem.children, { canPerform, isRoot }, depth + 1),
      });
    }
  });
  return trimmed;
}

/**
 * @param {object} options
 * @param {string} options.pathWithoutQueryParams
 * @param {import("./MenuItem").Item[]} options.menuItems
 * @return {string[]}
 */
function getInitialOpenedMenuItemsIds({
  pathWithoutQueryParams,
  menuItems = [],
}) {
  /** @type {string[]} */
  const openedMenuItemsIds = [];
  menuItems?.forEach((item) => {
    const isActive = isActivePath({
      pathWithoutQueryParams,
      href: item.href,
    });
    const isActiveDeep = isActivePath({
      pathWithoutQueryParams,
      href: item.href,
      children: item.children,
    });
    if (item.children.length > 0 && (isActive || isActiveDeep)) {
      openedMenuItemsIds.push(item.id);
    }
  });
  return openedMenuItemsIds;
}

/**
 * @typedef {{
 * menuItems: import("./MenuItem").Item[],
 * isOpened: boolean,
 * onClose: () => void,
 * isMobileLayout: boolean,
 * }} Props
 * @param {import("react").PropsWithChildren<Props>} props
 */
function Menu({ menuItems: _menuItems, isOpened, onClose, isMobileLayout }) {
  const router = useRouter();

  const canPerform = useCanPerform();

  const { block, unblock } = useScrollBlocker();

  const { remanentUser, root: isRoot, loading } = useAuthAs();

  const { colorMode, setColorMode } = useColorMode();

  const handleChangeTheme = useCallback(
    function (event) {
      setColorMode(event.target.checked ? "dark" : "light");
    },
    [setColorMode],
  );

  const menuItems = useMemo(() => {
    return prepare(_menuItems, { canPerform, isRoot });
  }, [_menuItems, canPerform, isRoot]);

  const [openedMenuItemsIds, setOpenedMenuItemsIds] = useState(() => {
    const pathWithoutQueryParams = router?.asPath?.split?.(/[?#]/)[0];
    return getInitialOpenedMenuItemsIds({
      menuItems,
      pathWithoutQueryParams,
    });
  });

  const openMenuItem = useCallback(
    /** @param {import("./MenuItem").Item} item */
    (item) => {
      if (item.depth === 0) {
        setOpenedMenuItemsIds([item.id]);
      } else {
        setOpenedMenuItemsIds((openedMenuItems) => [
          ...openedMenuItems,
          item.id,
        ]);
      }
    },
    [],
  );

  const closeMenuItem = useCallback(
    /** @param {import("./MenuItem").Item} item */
    (item) => {
      setOpenedMenuItemsIds((openedMenuItems) => {
        const newOpenedMenuItems = [...openedMenuItems];
        const index = newOpenedMenuItems.indexOf(item.id);
        if (index > -1) {
          newOpenedMenuItems.splice(index, 1);
        }
        return newOpenedMenuItems;
      });
    },
    [],
  );

  useEffect(() => {
    if (isOpened && isMobileLayout) {
      block();
    } else {
      unblock();
    }
  }, [block, isMobileLayout, isOpened, unblock]);

  return (
    <Flex
      position="fixed"
      top="4rem"
      left="0"
      bottom="0"
      right="0"
      pointerEvents="none">
      {/* backdrop */}
      <Box
        pointerEvents={isOpened && isMobileLayout ? "all" : "none"}
        position="absolute"
        top="0"
        left="0"
        bottom="0"
        right="0"
        backgroundColor="rgba(0, 0, 0, 0.25)"
        opacity={isOpened && isMobileLayout ? 1 : 0}
        transition="opacity 0.2s"
        onClick={onClose}
      />
      <Stack
        pointerEvents="all"
        w="15rem"
        overflowY="auto"
        spacing="0"
        backgroundColor="gray.700"
        transform={{
          base: isOpened ? "translateX(0)" : "translateX(-100%)",
          lg: "translateX(0)",
        }}
        transition="all 0.25s ease-in-out"
        boxShadow={{
          base: isOpened
            ? "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)"
            : "none",
          lg: "none",
        }}
        role="menubar">
        {isMobileLayout && (
          <>
            <Box px="16px" py="8px">
              <Switch
                size="sm"
                id="menu-theme-switcher"
                isChecked={"dark" === colorMode}
                onChange={handleChangeTheme}>
                <FormattedMessage
                  defaultMessage="{colorMode, select, dark {Thème sombre} other {Thème clair}}"
                  values={{ colorMode }}
                />
              </Switch>
            </Box>
            <DarkMode>
              <ChakraMenu placement={"bottom"} isLazy={true}>
                <ChakraMenuButton>
                  <Box px="16px" py="8px">
                    {loading ? (
                      <Spinner />
                    ) : (
                      <UsersAvatar size="xs" user={remanentUser} />
                    )}
                  </Box>
                </ChakraMenuButton>
                <Portal>
                  <UserMenu />
                </Portal>
              </ChakraMenu>
              <Divider />
            </DarkMode>
          </>
        )}
        {menuItems?.map?.((item, index) => (
          <MenuItem
            item={item}
            openedMenuItemsIds={openedMenuItemsIds}
            openMenuItem={openMenuItem}
            closeMenuItem={closeMenuItem}
            key={item.id}
          />
        ))}
      </Stack>
    </Flex>
  );
}

export default Menu;

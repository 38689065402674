// deps
import { mode } from "@chakra-ui/theme-tools";

export default {
  variants: {
    outline(props) {
      return {
        field: {
          borderColor: mode("gray.200", "gray.700")(props),
        },
      };
    },
  },
};

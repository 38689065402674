import { Box } from "@chakra-ui/react";
import {
  AUTHORIZATIONS_AUTHORIZATION_VALUE_ACCOUNTS_RECORDS_VALUE_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_COUNTRIES_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_INVOICES_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_VIEW_ANY,
} from "@raiden/library/constants/authorizations";
import {
  postsTypePluralMessage,
  POSTS_TYPE_LIST,
} from "@raiden/library/constants/posts";
import generateAdminPath from "@raiden/library/libraries/utils/generateAdminPath";
import {
  IoAirplaneOutline,
  IoDocumentTextOutline,
  IoFlagOutline,
  IoHomeOutline,
  IoNewspaperOutline,
  IoPeopleOutline,
  IoSettingsOutline,
  IoWalletOutline,
} from "react-icons/io5";
import { FormattedMessage } from "react-intl";
import uniqid from "uniqid";

/** @type {import("../containers/SignedInLayout/Menu/MenuItem").Item[]} */
const _MENU_ITEMS = [
  {
    title: <FormattedMessage defaultMessage="Accueil" />,
    icon: IoHomeOutline,
    href: generateAdminPath({ id: "dashboard" }),
  },
  {
    title: <FormattedMessage defaultMessage="Paramètres" />,
    icon: IoSettingsOutline,
    children: [
      {
        title: <FormattedMessage defaultMessage="Environnements" />,
        href: generateAdminPath({ id: "@environments.viewAny" }),
      },
      {
        title: <FormattedMessage defaultMessage="Groupes d'utilisateurs" />,
        href: generateAdminPath({ id: "@usersGroups.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_VIEW_ANY,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Administrateurs" />,
        href: generateAdminPath({ id: "@usersAdmins.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_VIEW_ANY,
          }),
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Système" />,
    icon: IoDocumentTextOutline,
    children: [
      {
        title: <FormattedMessage defaultMessage="Laravel Horizon" />,
        href: generateAdminPath({ id: "@internalHorizon.view" }),
        validator: ({ isRoot }) => isRoot,
      },
      {
        title: <FormattedMessage defaultMessage="Laravel Telescope" />,
        href: generateAdminPath({ id: "@internalTelescope.view" }),
        validator: ({ isRoot }) => isRoot,
      },
      {
        title: <FormattedMessage defaultMessage="Statut" />,
        href: generateAdminPath({ id: "@healthStatus.view" }),
        validator: ({ isRoot }) => isRoot,
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Contenus" />,
    icon: IoNewspaperOutline,
    validator: ({ canPerform }) =>
      canPerform({
        authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_VIEW_ANY,
      }),
    children: [
      ...POSTS_TYPE_LIST.map((postType) => ({
        title: (
          <Box sx={{ "&::first-letter": { textTransform: "uppercase" } }}>
            <FormattedMessage
              {...postsTypePluralMessage}
              values={{ type: postType.id }}
            />
          </Box>
        ),
        href: generateAdminPath({
          id: "@posts.viewAny",
          parameters: {
            postType: postType.id,
          },
        }),
      })),
      {
        title: <FormattedMessage defaultMessage="Menus" />,
        href: generateAdminPath({ id: "@menus.viewAny" }),
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Finance" />,
    icon: IoWalletOutline,
    children: [
      {
        title: <FormattedMessage defaultMessage="Factures" />,
        href: generateAdminPath({ id: "@invoices.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_INVOICES_VIEW_ANY,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Comptabilité" />,
        href: generateAdminPath({ id: "@accountRecords.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_ACCOUNTS_RECORDS_VALUE_VIEW_ANY,
          }),
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Clients" />,
    icon: IoPeopleOutline,
    href: generateAdminPath({ id: "@customers.viewAny" }),
    validator: ({ canPerform }) =>
      canPerform({
        authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_VIEW_ANY,
      }),
  },
  {
    title: <FormattedMessage defaultMessage="Transporteurs" />,
    icon: IoAirplaneOutline,
    href: generateAdminPath({ id: "@carriers.viewAny" }),
    validator: ({ canPerform }) =>
      canPerform({
        authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_VIEW_ANY,
      }),
  },
  {
    title: <FormattedMessage defaultMessage="Pays" />,
    icon: IoFlagOutline,
    href: generateAdminPath({ id: "@countries.viewAny" }),
    validator: ({ canPerform }) =>
      canPerform({
        authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_COUNTRIES_VIEW_ANY,
      }),
  },
];

/**
 * adds uniqid & depth to each item
 * @param {import("../containers/SignedInLayout/Menu/MenuItem").Item[]} items
 * @param {number} [depth]
 * @returns {import("../containers/SignedInLayout/Menu/MenuItem").Item[]}
 */
function prepare(items, depth = 0) {
  return items.map((item) => ({
    ...item,
    id: uniqid(),
    depth,
    children: item.children && prepare(item.children, depth + 1),
  }));
}

export const MENU_ITEMS = prepare(_MENU_ITEMS);

// helpers
import { usersCan, usersIsRoot } from "../../users";

/**
 * Retourne si l’utilisateur courant peut accéder à la page.
 * @param {object} param0
 * @param {object} param0.user
 * @param {object} param0.guard
 * @return {boolean}
 */
export default async function nextIsAuthorized({ user, guard }) {
  if (guard) {
    if (
      guard.authorizations &&
      !usersCan({ authorizations: guard.authorizations, user })
    ) {
      return false;
    }

    if (guard.root && !usersIsRoot({ user })) {
      return false;
    }
  }

  return true;
}

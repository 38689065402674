/* istanbul ignore file */

// deps
import { useCallback } from "react";
import cookie from "cookie";

// libraires
import generateApiUri from "../libraries/utils/generateApiUri";
import getInlineCookies from "@splitfire-agency/raiden-library/dist/libraries/utils/getInlineCookies";

// hooks
import useLocale from "./useLocale";
import useAuthAs from "./auth/as";

// constants
import browser from "../constants/browser";

async function fetchCsrfCookie() {
  return fetch(
    generateApiUri({
      id: "@sanctum.csrfCookie",
    }),
    {
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
    },
  ).then(async function (response) {
    if (!response.ok) {
      throw await response.json();
    }
  });
}

async function fetchResource(
  resource,
  init,
  options = {},
  { xsrfToken, retry: xsrfRetry = 0 },
) {
  const {
    inputMode = "json",
    outputMode = "json",
    resolveOk = true,
    logout,
  } = options;

  return fetch(resource, {
    ...init,
    headers: {
      ...init?.headers,
      ...("json" === inputMode && { "Content-Type": "application/json" }),
      ...("json" === outputMode && { Accept: "application/json" }),
      "X-XSRF-TOKEN": xsrfToken,
    },
    body: "json" === inputMode ? JSON.stringify(init.body) : init.body,
    credentials: init?.credentials ?? "include",
  }).then(async function (response) {
    let tempResponse = response;

    switch (outputMode) {
      case "json": {
        if ("application/json" === response.headers.get("content-type")) {
          tempResponse = await response.json();
        }
      }
    }

    if (response.ok) {
      switch (response.status) {
        case 419: {
          if (
            /**
             * Si on obtient une seconde fois de suite un token expiré, on s’arrête.
             * Ce cas ne devrait pas arriver.
             */
            xsrfRetry < 1
          ) {
            return fetchCsrfCookie().then(function () {
              const { "XSRF-TOKEN": xsrfToken } = cookie.parse(document.cookie);

              return fetchResource(resource, init, options, {
                xsrfToken,
                retry: xsrfRetry + 1,
              });
            });
          }

          break;
        }

        case 401: {
          logout();

          break;
        }
      }
    }

    if (resolveOk) {
      if (response.ok) {
        return tempResponse;
      } else {
        return Promise.reject({ body: tempResponse, status: response.status });
      }
    } else {
      if (response.ok) {
        return tempResponse;
      } else {
        return { body: tempResponse, status: response.status };
      }
    }
  });
}

/**
 * Défini la procédure pour effectuer une requête à l’API côté client.
 * @param {RequestInfo} resource
 * @param {RequestInit} init
 * @param {object} options
 * @param {string} [options.inputMode="json"] Format dans lequel sont envoyées les données.
 * @param {string} [options.outputMode="json"] Format dans lequel les données reçues doivent être retournées.
 * @param {boolean} [options.resolveOk=true] Est-ce qu’il faut rejetter la promesse si le code d’erreur est différent de 2xx.
 * @param {string} [options.locale]
 */
export function browserApiFetcher(resource, init, options = {}) {
  if (!browser) {
    throw new SyntaxError("Cannot use browserApiFetcher server-side.");
  }

  const { locale } = options;

  const cookies = cookie.parse(document.cookie);

  let xsrfToken = cookies["XSRF-TOKEN"];

  return Promise.resolve()
    .then(async function () {
      if (!xsrfToken) {
        return fetchCsrfCookie().then(function () {
          xsrfToken = cookie.parse(document.cookie)["XSRF-TOKEN"];
        });
      }
    })
    .then(function () {
      return fetchResource(
        resource,
        {
          ...init,
          headers: {
            ...init?.headers,
            ...(locale && { "Accept-Language": locale }),
          },
        },
        options,
        { xsrfToken },
      );
    });
}

/**
 * Défini la procédure pour effectuer une requête à l’API côté serveur.
 * @param {RequestInfo} resource
 * @param {RequestInit} [init]
 * @param {object} options
 * @param {string} [options.locale] Langue à utiliser pour effectuer la requête.
 * @param {string} [options.origin] Origine à utiliser pour effectuer la requête.
 * @param {object} [options.cookies={}] Cookies du client récupérés par le serveur
 * @param {boolean} [options.resolveOk=true] Est-ce qu’il faut rejetter la promesse si le code d’erreur est différent de 2xx.
 */
export function serverApiFetcher(resource, init, options = {}) {
  const { cookies = {}, locale, origin } = options;

  const xsrfToken = cookies["XSRF-TOKEN"];

  return Promise.resolve().then(function () {
    return fetchResource(
      resource,
      {
        ...init,
        headers: {
          ...init?.headers,
          ...{
            cookie: getInlineCookies(cookies),
          },
          ...(origin && {
            origin,
          }),
          ...(locale && { "Accept-Language": locale }),
        },
      },
      options,
      { xsrfToken },
    );
  });
}

export function useBrowserApiFetcher() {
  const { locale } = useLocale();

  return useCallback(
    function (resource, init, options = {}) {
      return browserApiFetcher(resource, init, { ...options, locale });
    },
    [locale],
  );
}

export default function useApiFetcher() {
  const { locale } = useLocale();
  const { logout } = useAuthAs();

  return useCallback(
    function (resource, init, options = {}) {
      return browserApiFetcher(resource, init, { ...options, locale, logout });
    },
    [locale, logout],
  );
}

// deps
// eslint-disable-next-line import/no-unresolved
import generateUri from "@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/generateUri";

// constants
import {
  adminRouter,
  adminRouterWithBasePath,
} from "../../../constants/routers/admin";

/**
 * Retourne le chemin de la route applicative demandée.
 * @param {object} options
 * @param {boolean} [options.includeBasePath]
 * @param {import ("@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router").default} [options.router]
 * @param {string} [options.id]
 * @param {object} [options.parameters]
 * @param {string} [options.scheme]
 * @param {string | {user: string; password: string}} [options.userInfo]
 * @param {string} [options.host]
 * @param {number} [options.port]
 * @param {string | object} [options.query]
 * @param {string} [options.fragment]
 * @return {string}
 */
export default function generateAdminPath(options = {}) {
  const { includeBasePath = false, ...param0 } = options;
  if (includeBasePath) {
    return generateUri({
      router: adminRouterWithBasePath,
      ...param0,
      host: null,
      userInfo: null,
      scheme: null,
    });
  }
  return generateUri({
    router: adminRouter,
    ...param0,
    host: null,
    userInfo: null,
    scheme: null,
  });
}

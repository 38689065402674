// deps
// eslint-disable-next-line import/no-unresolved
import generateUri from "@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/generateUri";

// constants
import {
  adminRouter,
  adminRouterWithBasePath,
} from "../../../constants/routers/admin";

/**
 * Retourne l’URI de la route API demandée.
 * @param {object} options
 * @param {boolean} [options.includeBasePath]
 * @param {object} [options.param0]
 * @param {import ("@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router").default} [options.param0.router]
 * @param {string} [options.param0.id]
 * @param {object} [options.param0.parameters]
 * @param {string} [options.param0.scheme]
 * @param {string | {user: string; password: string}} [options.param0.userInfo]
 * @param {string} [options.param0.host]
 * @param {number} [options.param0.port]
 * @param {string | object} [options.param0.query]
 * @param {string} [options.param0.fragment]
 * @return {string}
 */
export default function generateAdminUri(options = {}) {
  const { includeBasePath = false, ...param0 } = options;

  if (includeBasePath) {
    return generateUri({
      router: adminRouterWithBasePath,
      ...param0,
    });
  }
  return generateUri({
    router: adminRouter,
    ...param0,
  });
}

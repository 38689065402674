// polyfills
import "../polyfills";
import intlPolyfills from "../polyfills/intl";

// deps
import { SSRProvider } from "@react-aria/ssr";
import { I18nProvider } from "@react-aria/i18n";
import { OverlayProvider } from "@react-aria/overlays";
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";
import Head from "next/head";
import NextApp from "next/app";
import cookie from "cookie";
import { SWRConfig } from "swr";
import { ChakraProvider, cookieStorageManager } from "@chakra-ui/react";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import customParseFormat from "dayjs/plugin/customParseFormat";
import generateAdminPath from "@raiden/library/libraries/utils/generateAdminPath";

// containers
import MaintenanceLayout from "../containers/MaintenanceLayout";
import SignedInLayout from "../containers/SignedInLayout";
import SignedOutLayout from "../containers/SignedOutLayout";
import Progress from "../containers/Progress";
import Unauthorized from "../containers/Unauthorized";
import TooManyRequest from "../containers/TooManyRequest";
import CookieBanner from "../containers/CookieBanner";

// components
import ErrorBoundary from "../components/ErrorBoundary";

// hooks
import { useMaintenance } from "../hooks/maintenance";

// contexts
import { InitialVariablesProvider } from "@splitfire-agency/raiden-library/dist/contexts/InitialVariables";
import { PreferencesProvider } from "../contexts/Preferences";
import { GoogleTrackingProvider } from "@raiden/library/contexts/GoogleTracking";

// libraries
import generateAdminUri from "@raiden/library/libraries/utils/generateAdminUri";
import getTranslationMessages from "../libraries/utils/getTranslationMessages";

// helpers
import {
  nextCheckMaintenance,
  nextGetCookies,
  nextGetUser,
  nextGetConfiguration,
  nextGetLayout,
  nextIsAuthorized,
  nextGetEnvironments,
  nextGetUri,
} from "@raiden/library/helpers/next";
import { googleGetTrackingState } from "@raiden/library/helpers/google";

// constants
import theme from "../constants/theme";
import { adminBaseUri } from "@raiden/library/constants/routers/admin";
import { DEFAULT_PER_PAGE } from "@raiden/library/constants/api";
import {
  COOKIES_NAME_LIST,
  COOKIES_NAME_VALUE_ACCEPT_COOKIES,
  COOKIES_NAME_VALUE_PAGINATION_PER_PAGE,
  COOKIES_NAME_VALUE_PREFER_MENU_SHRINK,
} from "@raiden/library/constants/cookies";
import browser from "@raiden/library/constants/browser";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-image-crop/dist/ReactCrop.css";

dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);

const swrConfigValue = {
  revalidateOnFocus: false,
  errorRetryCount: 0,
  refreshWhenOffline: false,
};

/**
 * Retourne le layout à afficher.
 * @param {object} param0
 * @param {"signedIn" | "signedOut" | string} param0.layout
 * @return {any}
 */
function getLayout({ layout }) {
  switch (layout) {
    case "signedOut":
      return SignedOutLayout;

    case "signedIn": {
      return SignedInLayout;
    }

    default:
      return SignedInLayout;
  }
}

export default function App(props) {
  const {
    Component,
    pageProps,
    cookies,
    layout,
    tooManyRequest,
    authorized,
    userData,
    environmentsData,
    configuration,
    locale,
    translationMessages,
    acceptLanguage,
  } = props;

  const isMaintenance = useMaintenance({ cookies });

  const colorModeManager = cookieStorageManager(
    cookie.serialize("chakra-ui-color-mode", cookies["chakra-ui-color-mode"]),
  );

  const Layout = getLayout({ layout });

  return (
    <>
      <Head>
        <link
          rel="icon"
          href={generateAdminPath({
            id: "internal-assets",
            parameters: { filePath: "favicon.png" },
          })}
        />
      </Head>

      <GoogleTrackingProvider
        initialState={googleGetTrackingState({
          cookieValue: cookies[COOKIES_NAME_VALUE_ACCEPT_COOKIES],
        })}
        trackingKey={process.env.NEXT_PUBLIC_GOOGLE_TRACKING_KEY}
        trackingService={process.env.NEXT_PUBLIC_GOOGLE_TRACKING_SERVICE}>
        <InitialVariablesProvider
          cookies={cookies}
          userData={userData}
          configuration={configuration}
          acceptLanguage={acceptLanguage}>
          <SWRConfig value={swrConfigValue}>
            <IntlProvider
              locale={locale}
              defaultLocale={process.env.NEXT_PUBLIC_DEFAULT_LOCALE}
              messages={translationMessages}>
              <I18nProvider locale={locale}>
                <SSRProvider>
                  <ChakraProvider
                    theme={theme}
                    colorModeManager={colorModeManager}>
                    <Progress />

                    <PreferencesProvider
                      initialEnvironments={environmentsData?.data ?? []}
                      initialPreferMenuShrink={
                        cookies[COOKIES_NAME_VALUE_PREFER_MENU_SHRINK] === "1"
                      }
                      initialPaginationPerPage={
                        cookies[COOKIES_NAME_VALUE_PAGINATION_PER_PAGE] ??
                        DEFAULT_PER_PAGE
                      }>
                      <ErrorBoundary>
                        {isMaintenance ? (
                          <MaintenanceLayout />
                        ) : (
                          <Layout>
                            <OverlayProvider>
                              {(() => {
                                if (tooManyRequest) {
                                  return <TooManyRequest />;
                                }
                                if (!authorized) {
                                  return <Unauthorized />;
                                }
                                return (
                                  <>
                                    <Component {...pageProps} />
                                    <CookieBanner />
                                  </>
                                );
                              })()}
                            </OverlayProvider>
                          </Layout>
                        )}
                      </ErrorBoundary>
                    </PreferencesProvider>
                  </ChakraProvider>
                </SSRProvider>
              </I18nProvider>
            </IntlProvider>
          </SWRConfig>
        </InitialVariablesProvider>
      </GoogleTrackingProvider>
    </>
  );
}

App.getInitialProps = async function (appContext) {
  const {
    router: { locale },
  } = appContext;

  const defaultLocale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE;

  await nextCheckMaintenance({ res: appContext.ctx.res });

  const { cookies, whitelistCookies } = await nextGetCookies({
    req: appContext.ctx.req,
    whitelist: COOKIES_NAME_LIST.reduce(function (cookies, { id: cookieName }) {
      cookies[cookieName] = true;

      return cookies;
    }, {}),
  });

  const [appProps, translationMessages, userResponse, configurationResponse] =
    await Promise.all([
      NextApp.getInitialProps(appContext),
      getTranslationMessages(locale, defaultLocale),
      nextGetUser({ cookies, baseUri: adminBaseUri, locale }),
      nextGetConfiguration({ baseUri: adminBaseUri, locale }),
      intlPolyfills(locale),
    ]);

  const { configuration } = configurationResponse;

  const { data: userData, user, logged, error } = userResponse;

  const environmentsResponse = await nextGetEnvironments({
    cookies,
    baseUri: adminBaseUri,
    locale,
    logged,
  });

  const { data: environmentsData } = environmentsResponse;

  const tooManyRequest = 429 === error?.errors?.[0]?.status;

  const uri = nextGetUri({ req: appContext.ctx.req });

  const layout = await nextGetLayout({
    pageLayout: appProps?.pageProps?.layout,
    tooManyRequest,
    res: appContext.ctx.res,
    logged,
    user,
    redirections: {
      signedOut: generateAdminUri({
        id: "login",
        query: {
          next: encodeURIComponent(
            `${uri.getPath()}${uri.getQuery() ? `?${uri.getQuery()}` : ""}`,
          ),
        },
        includeBasePath: true,
      }),
      signedIn: generateAdminUri({ id: "dashboard", includeBasePath: true }),
    },
  });

  const authorized = await nextIsAuthorized({
    guard: appProps?.pageProps?.guard,
    user,
  });

  const acceptLanguage = browser
    ? window.navigator.languages.join(",")
    : appContext.ctx.req.headers["accept-language"];

  return {
    ...appProps,
    locale,
    layout,
    cookies: whitelistCookies,
    userData,
    environmentsData,
    tooManyRequest,
    authorized,
    configuration,
    translationMessages,
    acceptLanguage,
  };
};

App.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.any,
  locale: PropTypes.string,
  layout: PropTypes.string,
  cookies: PropTypes.object,
  authorized: PropTypes.bool,
  tooManyRequest: PropTypes.bool,
  userData: PropTypes.any,
  environmentsData: PropTypes.any,
  configuration: PropTypes.object,
  translationMessages: PropTypes.object,
};

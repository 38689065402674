// deps
import { PHASE_PRODUCTION_BUILD } from "next/constants";

// hooks
import { serverApiFetcher } from "../../../hooks/useApiFetcher";

// libraries
import generateApiUri from "../../../libraries/utils/generateApiUri";

export default async function nextGetConfiguration({ baseUri, locale }) {
  if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD) {
    return {
      data: {},
      configuration: undefined,
    };
  }

  const configurationData = await serverApiFetcher(
    generateApiUri({
      id: "@configuration",
      query: {
        fields: [
          "globals.covers",
          "globals.files",
          "environments",
          "records_main_accounts",
        ],
      },
    }),
    undefined,
    {
      origin: baseUri.toString(),
      locale,
    },
  );

  return {
    configuration: configurationData.data,
  };
}

// deps
import { PHASE_PRODUCTION_BUILD } from "next/constants";

// hooks
import { serverApiFetcher } from "../../../hooks/useApiFetcher";

// libraries
import generateApiUri from "../../../libraries/utils/generateApiUri";

/**
 * Récupère les informations sur l’utilisateur courant.
 * @param {object} param0
 * @param {object} param0.cookies
 * @param {import("@splitfire-agency/raiden-library/dist/libraries/Uri").default} param0.baseUri
 * @param {string} param0.locale
 * @return {Promise<object>}
 */
export default async function nextGetUser({ cookies, baseUri, locale }) {
  let error;

  if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD) {
    return {
      data: {},
      user: undefined,
      logged: false,
    };
  }

  const userData = await serverApiFetcher(
    generateApiUri({
      id: "@auth.me",
      query: {
        fields: ["user.avatar"],
      },
    }),
    undefined,
    {
      cookies,
      origin: baseUri.toString(),
      locale,
    },
  ).catch(function (tempError) {
    error = tempError;
  });

  const logged = Boolean(userData?.data?.id);

  return {
    data: userData,
    user: userData?.data,
    error,
    logged,
  };
}

export const LOCALES_LANG_NAMES = {
  fr: {
    id: "fr",
    name: "🇫🇷 Français",
  },
  en: {
    id: "en",
    name: "🇬🇧 English",
  },
};

export const LOCALES_LANG_NAME_LIST = Object.values(LOCALES_LANG_NAMES);

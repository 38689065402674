// deps
// eslint-disable-next-line import/no-unresolved
import Router from "@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router";
// eslint-disable-next-line import/no-unresolved
import Uri from "@splitfire-agency/raiden-library/dist/libraries/Uri";

const apiRouter = new Router();

apiRouter.group(
  // @ts-ignore
  new Uri(
    process.env.NEXT_PUBLIC_API_SCHEME,
    null,
    process.env.NEXT_PUBLIC_API_HOST,
    null,
    process.env.NEXT_PUBLIC_API_BASE_PATH,
  ),
  function (apiRouter) {
    apiRouter.group("/api", function (apiRouter) {
      // Configuration
      apiRouter.get("/configuration").setId("@configuration");

      // Environments
      apiRouter.group("/environments", function (apiRouter) {
        apiRouter.get("").setId("@environments.search");

        apiRouter.group("/<environmentId>", function (apiRouter) {
          apiRouter.setConstraint("environmentId", /\d+/);

          apiRouter.get("").setId("@environments.show");

          apiRouter.post("").setId("@environments.edit");

          apiRouter.delete("").setId("@environments.delete");
        });
      });

      // Users
      apiRouter.group("/users", function (apiRouter) {
        // Users/Groups
        apiRouter.group("/groups", function (apiRouter) {
          apiRouter.get("").setId("@usersGroups.search");

          apiRouter.post("").setId("@usersGroups.create");

          apiRouter.group("/<usersGroupId>", function (apiRouter) {
            apiRouter.setConstraint("usersGroupId", /\d+/);

            apiRouter.get("").setId("@usersGroups.show");

            apiRouter.post("").setId("@usersGroups.edit");

            apiRouter.delete("").setId("@usersGroups.delete");
          });
        });

        // Users/Admins
        apiRouter.group("/admins", function (apiRouter) {
          apiRouter.get("").setId("@usersAdmins.search");

          apiRouter.post("").setId("@usersAdmins.create");

          apiRouter.group("/<usersAdminId>", function (apiRouter) {
            apiRouter.setConstraint("usersAdminId", /\d+/);

            apiRouter.get("").setId("@usersAdmins.show");

            apiRouter.post("").setId("@usersAdmins.edit");

            apiRouter.delete("").setId("@usersAdmins.delete");
          });
        });

        // Users/PasswordResets
        apiRouter.group("/password-resets", function (apiRouter) {
          apiRouter.post("").setId("@usersPasswordResets.create");

          apiRouter.group("/action", function (apiRouter) {
            apiRouter
              .post("/finish")
              .setId("@usersPasswordResets.actionFinish");
          });
        });

        // Users/Customers
        apiRouter.group("/customers", function (apiRouter) {
          apiRouter.get("").setId("@customers.search");
          apiRouter.post("").setId("@customers.create");
          apiRouter.group("/<customerId>", function (apiRouter) {
            apiRouter.setConstraint("customerId", /\d+/);
            apiRouter.get("").setId("@customers.show");
            apiRouter.post("").setId("@customers.edit");
            apiRouter.delete("").setId("@customers.delete");
            apiRouter.group("/action", function (apiRouter) {
              apiRouter.post("/restore").setId("@customers.restore");
            });
          });
        });

        // Users/Addresses
        apiRouter.group("/addresses", function (apiRouter) {
          apiRouter.get("").setId("@addresses.search");
          apiRouter.post("").setId("@addresses.create");
          apiRouter.group("/<addressId>", function (apiRouter) {
            apiRouter.setConstraint("addressId", /\d+/);
            apiRouter.post("").setId("@addresses.edit");
            apiRouter.delete("").setId("@addresses.delete");
            apiRouter.group("/action", function (apiRouter) {
              apiRouter.post("/restore").setId("@addresses.restore");
            });
          });
        });
      });

      // Posts
      apiRouter.group("/posts", function (apiRouter) {
        apiRouter.get("").setId("@posts.search");

        apiRouter.post("").setId("@posts.create");

        apiRouter.group("/<postId>", function (apiRouter) {
          apiRouter.setConstraint("postId", /\d+/);

          apiRouter.get("").setId("@posts.show");

          apiRouter.post("").setId("@posts.edit");

          apiRouter.delete("").setId("@posts.delete");

          apiRouter.group("/action", function (apiRouter) {
            apiRouter.post("/restore").setId("@posts.restore");
          });
        });

        // Posts/Categories
        apiRouter.group("/categories", function (apiRouter) {
          apiRouter.get("").setId("@postsCategories.search");

          apiRouter.get("/tree").setId("@postsCategories.treeView");

          apiRouter.post("/tree").setId("@postsCategories.treeUpdate");

          apiRouter.post("").setId("@postsCategories.create");

          apiRouter.group("/<postsCategoryId>", function (apiRouter) {
            apiRouter.setConstraint("postsCategoryId", /\d+/);

            apiRouter.get("").setId("@postsCategories.show");

            apiRouter.post("").setId("@postsCategories.edit");

            apiRouter.post("").setId("@postsCategories.delete");

            apiRouter.group("/action", function (apiRouter) {
              apiRouter.group("/restore", function (apiRouter) {
                apiRouter.post("").setId("@postsCategories.restore");
              });
            });
          });
        });

        apiRouter.group("/revisions", function (apiRouter) {
          apiRouter.group("/<postsRevisionId>", function (apiRouter) {
            apiRouter.setConstraint("postsRevisionId", /\d+/);

            apiRouter.post("").setId("@postsRevisions.edit");

            apiRouter.delete("").setId("@postsRevisions.delete");

            apiRouter.group("/action", function (apiRouter) {
              apiRouter.post("/review").setId("@postsRevisions.review");

              apiRouter.post("/restore").setId("@postsRevisions.restore");
            });
          });
        });
      });

      // Covers
      apiRouter.group("/covers", function (apiRouter) {
        apiRouter.get("").setId("@covers.viewAny");

        apiRouter.post("").setId("@covers.create");

        apiRouter.group("/<coverId>", function (apiRouter) {
          apiRouter.setConstraint("coverId", /\d+/);

          apiRouter.post("").setId("@covers.edit");

          apiRouter.delete("").setId("@covers.delete");
        });
      });

      // Files
      apiRouter.group("/files", function (apiRouter) {
        apiRouter.get("").setId("@files.viewAny");

        apiRouter.post("").setId("@files.create");

        apiRouter.group("/<fileId>", function (apiRouter) {
          apiRouter.setConstraint("fileId", /\d+/);

          apiRouter.delete("").setId("@files.delete");

          apiRouter.get("").setId("@files.view");

          apiRouter.post("").setId("@files.update");

          apiRouter.group("/action", function (apiRouter) {
            apiRouter.post("/restore").setId("@files.restore");
          });
        });
      });

      // Countries
      apiRouter.group("/countries", function (apiRouter) {
        apiRouter.get("").setId("@countries.search");
        apiRouter.post("").setId("@countries.create");

        apiRouter.group("/<countryId>", function (apiRouter) {
          apiRouter.setConstraint("countryId", /\d+/);
          apiRouter.get("").setId("@countries.show");
          apiRouter.post("").setId("@countries.edit");
          apiRouter.post("").setId("@countries.delete");

          apiRouter.group("/action", function (apiRouter) {
            apiRouter.post("/restore").setId("@countries.restore");
          });
        });
      });

      // Menus
      apiRouter.group("/menus", function (apiRouter) {
        apiRouter.get("").setId("@menus.viewAny");
        apiRouter.post("").setId("@menus.create");

        apiRouter.group("/<menuId>", function (apiRouter) {
          apiRouter.setConstraint("menuId", /\d+/);
          apiRouter.get("").setId("@menus.view");
          apiRouter.post("").setId("@menus.update");
          apiRouter.post("").setId("@menus.delete");

          apiRouter.group("/action", function (apiRouter) {
            apiRouter.post("/restore").setId("@menus.restore");
          });
        });
      });

      // invoices
      apiRouter.group("/invoices", function (apiRouter) {
        apiRouter.get("").setId("@invoices.viewAny");

        // invoices/{invoiceId}
        apiRouter.group("/<invoiceId>", function (apiRouter) {
          apiRouter.setConstraint("invoiceId", /\d+/);

          // invoices/{invoiceId}/action
          apiRouter.group("/action", function (apiRouter) {
            apiRouter.get("/download").setId("@invoices.download");
          });
        });

        // invoices/{invoiceId}/action
        apiRouter.group("/action", function (apiRouter) {
          apiRouter.get("/export").setId("@invoices.export");
        });
      });

      // accounts
      apiRouter.group("/accounts", function (apiRouter) {
        // accounts/records
        apiRouter.group("/records", function (apiRouter) {
          // accounts/records/exports
          apiRouter.group("/exports", function (apiRouter) {
            apiRouter.get("").setId("@accountsRecordsExports.viewAny");
            // accounts/records/exports/{exportId}
            apiRouter.group("/<exportId>", function (apiRouter) {
              apiRouter.setConstraint("exportId", /\d+/);
              // accounts/records/exports/{exportId}/action
              apiRouter.group("/action", function (apiRouter) {
                apiRouter
                  .get("/download")
                  .setId("@accountsRecordsExports.download");
              });
            });
          });
        });
      });

      // Carriers
      apiRouter.group("/carriers", function (apiRouter) {
        apiRouter.get("").setId("@carriers.search");
        apiRouter.post("").setId("@carriers.create");

        apiRouter.group("/<carrierId>", function (apiRouter) {
          apiRouter.setConstraint("carrierId", /\d+/);
          apiRouter.get("").setId("@carriers.show");
          apiRouter.post("").setId("@carriers.edit");
          apiRouter.post("").setId("@carriers.delete");

          apiRouter.group("/action", function (apiRouter) {
            apiRouter.post("/restore").setId("@carriers.restore");
            apiRouter.post("/associate").setId("@carriers.associate");
            apiRouter.post("/dissociate").setId("@carriers.dissociate");
          });
        });
      });

      // Authorizations
      apiRouter.group("/authorizations", function (apiRouter) {
        apiRouter.get("").setId("@authorizations.viewAny");
      });
    });

    apiRouter.group("/auth", function (apiRouter) {
      apiRouter.post("/login").setId("@auth.login");

      apiRouter.post("/me").setId("@auth.me");

      apiRouter.post("/logout").setId("@auth.logout");
    });

    apiRouter.group("/sanctum", function (apiRouter) {
      apiRouter.group("/csrf-cookie", function (apiRouter) {
        apiRouter.get("").setId("@sanctum.csrfCookie");
      });
    });

    apiRouter.group("/laravel", function (apiRouter) {
      apiRouter.get("/telescope").setId("@laravel.telescope");

      apiRouter.get("/horizon").setId("@laravel.horizon");
    });
  },
);

export default apiRouter;

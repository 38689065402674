// deps
import { mode } from "@chakra-ui/theme-tools";

export default {
  variants: {
    striped(props) {
      const { colorScheme: c } = props;

      const oddBorderAndBackgroundColor =
        "brandPrimary" === c
          ? mode(`${c}.50`, `gray.700`)(props)
          : mode(`${c}.100`, `${c}.700`)(props);

      return {
        thead: {
          tr: {
            th: {
              borderColor: "transparent",
            },
          },
        },
        tbody: {
          tr: {
            borderColor: "transparent",
            "&:nth-of-type(odd)": {
              "th, td": {
                borderBottom: "0",
              },
              td: {
                background: oddBorderAndBackgroundColor,
              },
            },
            td: {
              borderColor: "transparent",
            },
          },
        },
      };
    },
    striped2(props) {
      const { colorScheme: c } = props;

      const oddBorderAndBackgroundColor =
        "brandPrimary" === c
          ? mode(`${c}.50`, `gray.600`)(props)
          : mode(`${c}.100`, `${c}.700`)(props);

      return {
        thead: {
          tr: {
            th: {
              borderColor: "transparent",
            },
          },
        },
        tbody: {
          tr: {
            borderColor: "transparent",
            "&:nth-of-type(odd)": {
              "th, td": {
                borderBottom: "0",
              },
              td: {
                background: oddBorderAndBackgroundColor,
              },
            },
            td: {
              borderColor: "transparent",
            },
          },
        },
      };
    },
  },
  defaultProps: {
    colorScheme: "brandPrimary",
  },
};
